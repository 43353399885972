const general = {
    placeholder: 'Segnaposto',
    submit: 'Invia',
    skip: 'Salta',
    next: 'Avanti',
    back: 'Indietro',
    retry: 'Riprova',
    done: 'Fatto',
    search: 'Cerca',
    search_placeholder: 'Cerca',
    clear_result: 'Cancella risultati',
    save: 'Salva',
    save_changes: 'Salva modifiche',
    saved: 'Salvato',
    discard: 'Elimina',
    download: 'Scarica',
    loading: 'Caricamento...',
    redirecting: 'Redirezione...',
    add: 'Aggiungi',
    added: 'Aggiunto',
    cancel: 'Annulla',
    confirm: 'Conferma',
    check_out: 'Checkout',
    create: 'Crea',
    set_up: 'Imposta',
    customize: 'Personalizza',
    enable: 'Abilita',
    reminder: 'Promemoria',
    edit: 'Modifica',
    delete: 'Elimina',
    deleted: 'Eliminato',
    activate: 'Attiva',
    deactivate: 'Disattiva',
    more_options: 'PIÙ OPZIONI',
    close: 'Chiudi',
    copy: 'Copia',
    copying: 'Copiare',
    copied: 'Copiato',
    required: 'Obbligatorio',
    add_another: 'Aggiungi un altro',
    create_another: 'Crea un altro',
    deletion_confirmation: 'Sei sicuro di voler eliminare questo {{title}}?',
    settings_nav: 'Impostazioni',
    unsaved_changes_warning: 'Hai apportato alcune modifiche. Sei sicuro di voler lasciare questa pagina?',
    leave_page: 'Lascia pagina',
    stay_on_page: 'Rimani sulla pagina',
    type_to_search: 'Digita per cercare',
    got_it: 'Capito',
    continue: 'Continua',
    page_info: '{{min, number}}-{{max, number}} di {{total, number}}',
    learn_more: 'Scopri di più',
    tab_error_one: '{{count, number}} errore',
    tab_error_other: '{{count, number}} errori',
    skip_for_now: 'Salta per ora',
    remove: 'Rimuovi',
    visit: 'Visita',
    join: 'Unisciti',
    try_now: 'Prova ora',
    multiple_form_field: '(Multiplo)',
    demo: 'Demo',
    unnamed: 'Senza nome',
    view: 'Vista',
    open: 'Apri',
    hide: 'Nascondi',
    unknown_error: 'Errore sconosciuto, riprova più tardi.',
    select: 'Seleziona',
    contact_us_action: 'Contattaci',
    description: 'Descrizione',
    name: 'Nome',
    add_field: 'Aggiungi {{field}}',
    create_field: 'Crea {{field}}',
    edit_field: 'Modifica {{field}}',
    delete_field: 'Elimina {{field}}',
    coming_soon: 'Prossimamente',
    or: 'O',
    reset: 'Ripristina',
    generate: 'Genera',
};
export default Object.freeze(general);
